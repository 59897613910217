import React from 'react';
import Texts from '../../Texts'
import './TextContainer.css';

function renderText(id, lang='hr') {
  let paragraphs = [];
  let parts = Texts[id][lang].split("~");

  parts.forEach((p,i) => {
    paragraphs.push(<p key={i}>{p}</p>);
  });

  return paragraphs;
}

function TextContainer({id,lang}) {
  return (
    <div className='text-container'>
      {renderText(id,lang)}
    </div>
  );
}

export default TextContainer;
