import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './Pagination.css';

function renderPagination(count, page, callback) {
  if (count < 12) {
    return null;
  }

  let pages = Math.ceil(count/12);
  let links = [];

  let start = 1;
  let end = pages;

  if (pages > 5) {
    if (page - 2 < 1) {
      start = 1;
      end = 5;
    } else if (page + 2 > pages) {
      start = pages - 4;
      end = pages;
    } else {
      start = page - 2;
      end = page + 2;
    }
  }

  for (var i = start; i <= end; i++) {
    links.push(
      <PaginationItem
        onClick={callback.bind(null, i)}
        key={i}
        active={i === page}>
        <PaginationLink>{i}</PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <Pagination>
      <PaginationItem>
        <PaginationLink first onClick={callback.bind(null, 1)}/>
      </PaginationItem>
      <PaginationItem disabled={page === 1}>
        <PaginationLink previous onClick={callback.bind(null, page - 1)}/>
      </PaginationItem>
      {links}
      <PaginationItem disabled={page === pages}>
        <PaginationLink next onClick={callback.bind(null, page + 1)}/>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last onClick={callback.bind(null, pages)}/>
      </PaginationItem>
    </Pagination>
  )
}

class PaginationMKM extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="pagination-mkm-container">
        {renderPagination(this.props.count, this.props.page, this.props.callback)}
      </div>
    );
  }
}

export default PaginationMKM;
