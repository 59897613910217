import React from 'react';
import Map from '../../components/map/Map';
import './Contact.css';

function Contact() {
  return (
    <div className='contact-container'>
      <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td>Telefon:</td>
              <td>095 845-5905</td>
            </tr>

            <tr>
              <td>E-mail:</td>
              <td>strigamario@gmail.com</td>
            </tr>

            <tr>
              <td>Facebook:</td>
              <td>
                <a href="https://www.facebook.com/aluminijske.ograde.mkm">https://www.facebook.com/aluminijske.ograde.mkm</a>
              </td>
            </tr>

            <tr>
              <td>Adresa:</td>
              <td>Ključ, V. Čolje 35</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='map-container'>
        <Map />
      </div>
    </ div>
  );
}

export default Contact;
