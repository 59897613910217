import React, { Component } from 'react';
import ImageGalery from '../../components/image_galery/ImageGalery'
import ImagesCount from '../../ImagesCount'
import TextContainer from '../../components/text_container/TextContainer'
import './Showers.css';

class Showers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  onChangePage(page) {
    this.setState({ page });
  }

  render() {
    return (
      <div className="showers">
        {this.state.page === 1 ? <TextContainer id="showers" lang="hr" /> : ""}
        <ImageGalery
          category="Showers"
          count={ImagesCount["Showers"]}
          onChangePage={this.onChangePage.bind(this)}
        />
      </div>
    );
  }
}

export default Showers;
