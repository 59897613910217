import React, { Component } from 'react';
import Thumbnail from '../thumbnail/Thumbnail';
import Pagination from '../pagination/Pagination';
import ImageModal from '../../containers/image_modal/ImageModal'
import './ImageGalery.css';

function renderThumbnails(folder, count, page, click) {
  let thumbnails = [];

  let start = (page - 1) * 12 + 1;
  let end = start + 12 > count ? count + 1 : start + 12;

  for (var i = start; i < end; i++) {
    thumbnails.push(
      <Thumbnail src={`/${folder}/${folder}${i}.jpg`} key={i} click={click.bind(null, i)}/>
    )
  }
  return thumbnails;
}

class ImageGalery extends Component {
  constructor(props) {
    super(props);

    this.getImageSrcFromNum = this.getImageSrcFromNum.bind(this);

    this.state = {
      page: 1,
      showModal: false,
      modalSrc: this.getImageSrcFromNum(1),
      modalImgNum: 1
    };
  }

  openModal(i) {
    this.setState({
      showModal: true,
      modalSrc: this.getImageSrcFromNum(i),
      modalImgNum: i
    });
  }

  closeModal() {
    this.setState({showModal: false});
  }

  changePage(page) {
    this.setState({page: page});
    if (this.props.onChangePage) {
      this.props.onChangePage(page);
    }
  }

  getImageSrcFromNum(num) {
    return `/Full/${this.props.category}/${this.props.category}${num}.jpg`;
  }

  setModalImgNum(modalImgNum) {
    if (modalImgNum > 0 && modalImgNum <= this.props.count) {
      this.setState({
        modalImgNum,
        modalSrc: this.getImageSrcFromNum(modalImgNum)
      });
    }
  }

  render() {
    return (
      <div className="galery-container">
        {renderThumbnails(this.props.category, this.props.count, this.state.page, this.openModal.bind(this))}
        <Pagination
          count={this.props.count}
          page={this.state.page}
          callback={this.changePage.bind(this)}
        />
        <ImageModal
          num={this.state.modalImgNum}
          src={this.state.modalSrc}
          count={this.props.count}
          open={this.state.showModal}
          close={this.closeModal.bind(this)}
          setNum={this.setModalImgNum.bind(this)}/>
      </div>
    );
  }
}

export default ImageGalery;
