import React, { Component } from 'react';
import './Map.css';

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe title="map" width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Klju%C4%8D%2C%20V.%20%C4%8Colje%2035&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
        </div>
      </div>
    );
  }
}

export default Map;
