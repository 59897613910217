import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import './Carousel.css';

const items = [
  {
    src: '/carousel/Slide1',
    caption: '',
    altText: 'Slide 1'
  },
  {
    src: '/carousel/Slide2',
    caption: '',
    altText: 'Slide 2'
  },
  {
    src: '/carousel/Slide3',
    caption: '',
    altText: 'Slide 3'
  },
  {
    src: '/carousel/Slide4',
    caption: '',
    altText: 'Slide 4'
  },
  {
    src: '/carousel/Slide5',
    caption: '',
    altText: 'Slide 5'
  }
];

const ImageCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <picture>
          <source media='(min-width: 1200px)' srcSet={item.src+'.jpg'} />
          <source media='(min-width: 768px)' srcSet={item.src+'M.jpg'} />
          <img src={item.src+'S.jpg'} alt={item.altText} />
        </picture>
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      pause={false}
      ride='carousel'
      interval='3500'
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default ImageCarousel;
