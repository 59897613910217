import React from 'react';
import { Switch, Route, HashRouter as Router } from 'react-router-dom';
import Home from './containers/home/Home';
import Contact from './containers/contact/Contact';
import Header from './containers/header/Header';
import AluFencesBalcony from './containers/alu_fences_balcony/AluFencesBalcony';
import AluFencesYard from './containers/alu_fences_yard/AluFencesYard';
import GlassFences from './containers/glass_fences/GlassFences';
import GlassWalls from './containers/glass_walls/GlassWalls';
import Showers from './containers/showers/Showers';
import Pergole from './containers/pergole/Pergole';
import './App.css';

function App() {
  return (
    <Router>
      <div id="appElement" className="app">
        <Header />
        <div className="main-container">
          <main>
            <Switch>
              <Route exact path="/Home" component={Home} />
              <Route exact path="/AluFencesBalcony" component={AluFencesBalcony} />
              <Route exact path="/AluFencesYard" component={AluFencesYard} />
              <Route exact path="/GlassFences" component={GlassFences} />
              <Route exact path="/GlassWalls" component={GlassWalls} />
              <Route exact path="/Contact" component={Contact} />
              <Route exact path="/Showers" component={Showers} />
              <Route exact path="/Pergole" component={Pergole} />
              <Route component={Home} />
            </Switch>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
