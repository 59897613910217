import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './logo.jpg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <header>
        <img id="mkm-logo" src={logo} alt="Logo"/>
        <Navbar light expand="sm">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="w-100 nav-justified" navbar>
              <NavItem>
                <Link to="/Home" className="nav-link">Naslovna</Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Galerija
                </DropdownToggle>
                <DropdownMenu className="w-100 text-center">
                  <DropdownItem>
                    <Link to="/AluFencesYard" className="nav-link">Dvorišne aluminijske ograde</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/AluFencesBalcony" className="nav-link">Balkonske aluminijske ograde</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/GlassFences" className="nav-link">Staklene ograde</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/GlassWalls" className="nav-link">Staklene stijene</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/Showers" className="nav-link">Tuš kabine</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/Pergole" className="nav-link">Pergole</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Link to="/Contact" className="nav-link">Kontakt</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

      </header>
    );
  }
}

export default Header;
