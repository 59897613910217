import React from 'react';
import './Thumbnail.css';

function Thumbnail({src, click}) {
  return (
    <div className="thumbnail-container">
      <div className="thumbnail-frame">
        <a className="small-only" href={`Full${src}`}>
          <img src={src} alt="Product"/>
        </a>
        <img className="large-only" onClick={click} src={src} alt="Product"/>
      </div>
    </div>
  );
}

export default Thumbnail;
