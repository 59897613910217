import React, { Component } from 'react';
import ImageGalery from '../../components/image_galery/ImageGalery'
import ImagesCount from '../../ImagesCount'
import TextContainer from '../../components/text_container/TextContainer'
import './AluFencesYard.css';

class AluFencesYard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  onChangePage(page) {
    this.setState({ page });
  }

  render() {
    return (
      <div className="alu-fences-balcony-container">
        {this.state.page === 1 ? <TextContainer id="alu_fences_yard" lang="hr" /> : ""}
        <ImageGalery
          category="AluFencesYard"
          count={ImagesCount["AluFencesYard"]}
          onChangePage={this.onChangePage.bind(this)}
        />
      </div>
    );
  }
}

export default AluFencesYard;
