import React, { Component } from 'react';
import './ImageModal.css';
import Modal from 'react-modal';
import { ReactComponent as Left } from './chevron-left.svg';
import { ReactComponent as Right } from './chevron-right.svg';
import { ReactComponent as Close } from './x.svg';

Modal.setAppElement('#root');

class ImageModal extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <Modal isOpen={this.props.open}
        onRequestClose={this.props.close}
        contentLabel="Image modal">
        <Close className='close-button' onClick={this.props.close} />
        { this.props.num > 1 ? <Left className='prev-button' onClick={() => this.props.setNum(this.props.num-1)} /> : '' }
        { this.props.num < this.props.count ? <Right className='next-button' onClick={() => this.props.setNum(this.props.num+1)} /> : '' }
        <img src={this.props.src} alt='Bigger version'/>
      </Modal>
    );
  }
}

export default ImageModal;
