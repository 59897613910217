import React, { Component } from 'react';
import ImageGalery from '../../components/image_galery/ImageGalery'
import ImagesCount from '../../ImagesCount'
import TextContainer from '../../components/text_container/TextContainer'
import './GlassFences.css';

class GlassFences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  onChangePage(page) {
    this.setState({ page });
  }

  render() {
    return (
      <div className="glass-fences-container">
        {this.state.page === 1 ? <TextContainer id="glass_fences" lang="hr" /> : ""}
        <ImageGalery
          category="GlassFences"
          count={ImagesCount["GlassFences"]}
          onChangePage={this.onChangePage.bind(this)}
        />
      </div>
    );
  }
}

export default GlassFences;
