import React from 'react';
import Carousel from '../../components/carousel/Carousel'
import TextContainer from '../../components/text_container/TextContainer'
import './Home.css';

function Home() {
  return (
    <div className='home-container'>
      <Carousel />
      <TextContainer id='home' lang='hr' />
    </ div>
  );
}

export default Home;
